/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Flex, Button } from 'rebass';

import { H2, H3, H4, P } from '../../../components/Text';
import { Layout } from '../../../layouts/Layout';
import { PartnerLayout } from '../../../layouts/PartnerLayout';
import { Container } from '../../../components/Container';

const Section: React.FC = ({ children }) => (
  <Box sx={{ my: [4, 6], display: 'flex', flexDirection: ['column', 'row'] }}>
    {children}
  </Box>
);

const sponsorshipDeckURL =
  'https://drive.google.com/file/d/1EeDCLNhHhmVchtis1jUPPMUxGiiQatbW/view?usp=sharing';

const volunteerURL = 'http://bit.ly/Interaction21Volunteer';

const GetInvolvedPage = () => {
  return (
    <PartnerLayout>
      <Container>
        <Box
          sx={{
            height: 426,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <H2 sx={{ mb: [3, 4], textAlign: 'center', maxWidth: 700 }}>
            Collectivement, nous pouvons faire une différence en ces temps
            périlleux.
          </H2>
        </Box>
        {/* Sponsor section  */}
        <Section css={{ flexDirection: 'column' }}>
          <H3 sx={{ flex: 2 }}>Devenir partenaire</H3>
          <Box sx={{ flex: 3, display: 'flex', flexDirection: 'column' }}>
            <H4>
              Entrez en contact avec des designers au plus grand événement de
              l'industrie
            </H4>
            <P>
              Interaction 21 offre aux travailleurs et aux entreprises la chance
              de réfléchir à comment nous donnons forme au monde, en bâtissant
              des ponts à travers les cultures, disciplines et industries pour y
              découvrir des buts et orientations partagés.
              <Flex>
                <Button
                  as="a"
                  href="mailto:sponsorship@ixda.org"
                  sx={{ mt: 3 }}
                >
                  Contactez-nous →
                </Button>
              </Flex>
            </P>
            <H4>Pourquoi devenir partenaire?</H4>
            <P sx={{ fontWeight: 'bold' }}>
              IxDA est reconnu pour ses conférences impressionantes, une
              tradition rendue possible grâce à nos commanditaires. Interaction
              21 est une occasion clé pour nos partenaires de rejoindre la
              communauté du design interactif et ses parties prenantes.
            </P>
            <P>
              Nous souhaitons addresser des sujets qui résonneront avec vous
              tout au long de l'année, générant ainsi de milliers de nouveaux
              contacts, et faisant progresser le design d'interactions partout
              dans le monde.
            </P>
            <P>
              Interaction est réputée d'attirer 1 500 personnes à nos
              conférences présentielles, et nous anticipons une diversité tout
              aussi grande qui devrait aller au delà de ce chiffre. Nos
              participants oeuvrent dans une multitude de sous-disciplines du
              design: design d'interactions, consultants en expérience
              utilisateur, gestionnaires de produit, académiciens, mais aussi
              des leaders d'opinion sur l'expérience utilisateur, des VPs de
              compagnies Fortune 100, et des OBNL orientés vers le futur.
            </P>
            <P>
              Cette année, nous implémentons un "Programme 1:1" - fournissant
              ainsi, à l'achat de votre billet, un billet offert pour quelqu'un
              dans la communauté mondiale qui n'en aurait pas les moyens.
            </P>
            <H4>Les forfaits Interaction 21 partent vite.</H4>
            <P>
              Les occasions incluent des événements sociaux, des ateliers,
              programmes de soirée, et espaces d'exposition. Contactez-nous au{' '}
              <a
                sx={{ variant: 'variants.link' }}
                href="mailto:sponsorship@ixda.org"
              >
                sponsorship@ixda.org
              </a>
              .
            </P>
            <Flex>
              <Button
                as="a"
                target="_blank"
                href={sponsorshipDeckURL}
                sx={{ mt: 3 }}
              >
                Télécharger le PDF (EN, 112KB)
              </Button>
            </Flex>
          </Box>
        </Section>

        {/* Volunteer section  */}
        <Section>
          <H3 sx={{ flex: 2 }}>Devenez bénévole</H3>
          <Box sx={{ flex: 3, display: 'flex', flexDirection: 'column' }}>
            <P>
              Êtes-vous intéressé à être dans le feu de l'action avec une équipe
              de gens dévoués? Si c'est le cas, nous voulons vous entendre!
              Veuillez remplir notre{' '}
              <a
                href={volunteerURL}
                target="_blank"
                sx={{ variant: 'variants.link' }}
              >
                formulaire de demande de bénévole
              </a>{' '}
              et une personne de notre équipe vous contactera.
            </P>
            <Flex>
              <Button
                as="a"
                href={volunteerURL}
                target="_blank"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: ['100%', 'auto'],
                  mt: 3,
                }}
              >
                Devenez bénévole ↗
              </Button>
            </Flex>
          </Box>
        </Section>
      </Container>
    </PartnerLayout>
  );
};

export default GetInvolvedPage;
